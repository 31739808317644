import React, { Suspense, setGlobal } from 'reactn';
import ReactDOM from 'react-dom';
import reportWebVitals from './reportWebVitals';
import 'antd/dist/antd.css';
import kitechLogo from './assets/white_transparent.png';
const App = React.lazy(() => import('./App'));

// Replace the LoadingOutlined with a custom KiTech logo component
const KiTechLogo = () => (
  <div className="logo-spinner">
    <img 
      src={kitechLogo} 
      alt="KITECH Logo" 
      style={{ 
        width: '80px',
        height: 'auto',
        animation: 'spin 3s linear infinite'
      }} 
    />
    <style>{`
      @keyframes spin {
        0% { transform: rotateY(0deg); }
        100% { transform: rotateY(360deg); }
      }
      .logo-spinner {
        display: flex;
        justify-content: center;
        align-items: center;
      }
    `}</style>
  </div>
);

const style = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100vh',
  backgroundColor: '#3399ff',
  color: 'white'
}

setGlobal({
  cart: [],
  domain: '',
  hosting: '',
  current: 0
})

ReactDOM.render(
  <React.StrictMode>
    <Suspense fallback={<div style={style}><KiTechLogo /></div>}>
      <App />
    </Suspense>
  </React.StrictMode>,
  document.getElementById('root')
);


// src/index.js or src/App.js

// Register the service worker
if ('serviceWorker' in navigator) {
  window.addEventListener('load', () => {
    navigator.serviceWorker.register('/service-worker.js')
      .then(registration => {
        console.log('Service Worker registered with scope:', registration.scope);
      })
      .catch(error => {
        console.error('Service Worker registration failed:', error);
      });
  });
}


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
